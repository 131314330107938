/* body styling */

.home_div {
  position: relative;
  background-color: #d0230d;
  height: auto;
  width: 100vw;
}

/* header styling */

header {
  /* background-color: #d0230d; */
  color: white;
  height: 58px;
  letter-spacing: 0.3px;
}

.header_div {
  z-index: 6;
  background-color: #d0230d;
  position: relative;
}

#h2 {
  font-weight: 700;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  margin: 0;
  display: none;
}

.header_phone_number {
  font-size: 20px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  color: white;
  text-decoration: none;
  padding-right: 15px;
}

.header_phone_number_icon {
  font-size: 25px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  color: white;
  text-decoration: none;
  display: none;
}

#mobileHeadingH2 {
  display: none;
}

.phone_icon {
  font-size: 18px;
  margin-right: 12px;
}

.phone_number_div {
  /* padding: 10px; */
  margin-right: 5px;
  /* width: 226px; */
  height: 50px;
}

.h2_div {
  margin-left: 121px;
}

/* Navbar styling */

.navlinks {
  width: 443px;
}

.nav-link {
  color: white !important;
}

.navbar {
  z-index: 5;
}

/* logo styling */

.logo {
  object-fit: cover;
  object-position: 98%;
  width: 133px;
  height: 68px;
  margin-top: -1px;
}

.image_div {
  width: 138px;
  height: 70px;
  overflow: hidden;
  border-radius: 50%;
  object-position: 75% 0;
  border: 4px solid white;
  position: absolute;
  top: 6px;
  left: 33px;
  z-index: 6;
}

/* SafeStorage component styling */

.safeStorage {
  background-color: white;
  height: 332px;
  width: auto;
  margin-left: 15px;
  position: relative;
  overflow: hidden;
}

.hope_storage_mobile_heading {
  display: none;
}

.safeStorage_heading_div {
  position: relative;
  left: 91px;
  top: 68px;
  width: 393px;
}

.safeStorage_heading_div h1 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 50px;
}

.outerDivBottomLeftCorner {
  position: relative;
  background-color: pink;
  z-index: 999;
}

.contactBtnDiv {
  position: relative;
  left: 91px;
  top: 80px;
}

.contactBtnDiv button {
  width: 168px;
  height: 48px;
  border-radius: 100px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: #d0230d;
  color: white;
  border: none;
  text-align: center;
  /* font-family: "Roboto", sans-serif; */
  font-family: "Nunito", sans-serif;

  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 83.333% */
  letter-spacing: 0.1px;
  transition: 0.3s;
}

.contactBtnDiv button:hover {
  background-color: #a91f0c;
}

.caravanImgDiv {
  height: 427px;
  width: 635px;
  overflow: hidden;
  /* object-position: 75% 0; */
  position: absolute;
  top: -70px;
  right: -100px;
  border-radius: 1800px 0px 0px 800px;
  z-index: 1;
}

.caravanImg {
  object-fit: cover;
  object-position: 35%;
  width: 900px;
  height: 900px;
  margin-top: -300px;
}

.bottomLeftCorner {
  height: 120px;
  width: 120px;
  z-index: 99999;
  background-color: green;
  position: absolute;
  top: 282px;
  border-radius: 80px 0 0 320px;
  left: 18px;
}

/* Mobile caravan div styling */

.mobile_caravan_img_div {
  display: none;
}

/* WhatWeOffer styling */

.whatWeOfferDiv {
  background-color: black;
  position: relative;
  color: white;
  height: 489px;
  width: auto;
  margin-left: 15px;
  position: relative;
  z-index: 2;
}

.whatWeOfferDiv h2 {
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: relative;
  top: 56px;
}

.carouselDiv {
  overflow: hidden;
}

.carousel_position_1 {
  left: 0%;
  display: flex;
  width: max-content;
  position: relative;
  transition: 0.6s;
}

.carousel_position_2 {
  left: -33%;
  display: flex;
  width: max-content;
  position: relative;
  transition: 0.6s;
}
.carousel_position_3 {
  left: -66.5%;
  display: flex;
  width: max-content;
  position: relative;
  transition: 0.6s;
}

.carousel_containers {
  /* Arrow height changes KD-001*/
  position: relative; /*KD-001*/
  background: black;
  width: 32.5vw;
  height: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel_inner_containers {
  background-color: rgb(38, 38, 38);
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 85%;
  height: 216px;
  border-radius: 5px;
}

.carouselDiv {
  /* Arrow height changes KD-001*/
  position: relative; /*KD-001*/
  margin-top: 90px;
}

.carouselDiv h3 {
  margin-bottom: 16px;
}

.carousel_arrows {
  color: #777777;
  position: absolute;
  font-size: 3em;
  top: calc(50% - 19px);
}

.legend {
  font-family: "Nunito", sans-serif;
}

.icons {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 2em;
}

.left_arrow {
  left: 3%;
  z-index: 99;
}

.right_arrow {
  right: 2%;
  z-index: 99;
}

.btn-slide {
  height: 38px;
  width: 45px;
  position: relative;
  /* top: 38px; KD-001 */
  background-color: rgba(250, 235, 215, 0);
  border: none;
}

.arrow_img {
  top: -27px;
  left: -10px;
  position: relative;
  color: #959595;
  transition: 0.3s;
  font-size: 32px;
}

.arrow_img:hover {
  color: #ececec;
}

/* Need to margin left -32.5vw for each click */
/* Need to left nd right arrows
Need 5 circles at the bottom */

/* HowItWorks styling */

.howItWorksDiv {
  background-color: white;
  height: 489px;
  width: auto;
  margin-left: 15px;
  position: relative;
  z-index: 2;
  text-align: center;
  padding-top: 64px;
}

.howItWorksDiv h2 {
  color: #000000;
  margin-bottom: 0;
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.howItWorksDiv h3 {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
}

.howItWorksDiv p {
  font-family: "Nunito", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.howItWorksImgDiv {
  width: 250px;
}

.howItWorksImgContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 24px 120px 0 120px;
}

.howItWorksImages {
  width: 250px;
  height: 220px;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: 17px;
}

/* Red banner styling */

.redBanner {
  height: 124px;
  /* width: auto; */
  display: flex;
  align-items: center;
}

.redBanner h2 {
  padding-left: 90px;
  color: white;
  font-family: Roboto;
  font-size: 35px;
  font-weight: 300;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: center;
}

/* Footer banner image styling */
.footerBanner {
  margin-left: 15px;
  height: 285px;
  width: 99%;
}

.footerBannerImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 0 48%;
}

/* Contact info styling */

.contactInfo {
  height: 519px;
  background: black;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  text-align: center;
}

.sendMessageFormDiv {
  text-align: left;
  background: rgb(229 229 229 / 0%);
  color: white;
  width: 293px;
  height: 430px;
}

.sendMessageFormDiv h3 {
  font-family: Inter;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 10px;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 89%;
}
/* 
MUI Adjustments */

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: #ffffff91 !important;
}

/* Below is id3 conversion */
.css-igs3ac {
  border-color: #ffffff91 !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
}

/* Below is 14s conversion */
.css-p0rm37 {
  color: white !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: white !important;
}

/* Below is 1jy conversion */
.css-1ald77x.Mui-focused {
  color: white !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  color: white !important;
}

/* Below is it8 conversion */
.css-1x5jdmq {
  color: white !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: #ffffff9c !important;
}

/* Below is 1jy conversion */
.css-1ald77x {
  color: #ffffff9c !important;
}

/* Hover autofil color */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px rgb(13, 13, 13) inset;
  -webkit-text-fill-color: #ffffff;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px rgba(34, 34, 34, 0.936) inset;
  -webkit-text-fill-color: #d7d7d7;
}

input:-webkit-autofill:required {
  -webkit-box-shadow: 0 0 0px 1000px rgb(13, 13, 13) inset;
  -webkit-text-fill-color: #d7d7d7;
}

input:-webkit-autofill:required:focus {
  -webkit-box-shadow: 0 0 0px 1000px rgba(34, 34, 34, 0.936) inset;
  -webkit-text-fill-color: #d7d7d7;
}

/* Label at top */
/* .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root */

/* Label at top - supposed to be hidden */
/* .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root */

/* Normal label */
/* .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root */

.sendMessageFormDiv .sendBtn {
  padding: 0;
  width: 293px;
  height: 40px;
  border-radius: 100px;
  border: none;
  background-color: rgb(201, 0, 0);
  color: white;
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: center;
  transition: 0.3s;
  margin-top: 8px;
}

.sendMessageFormDiv .sendBtn:hover {
  color: white;
  background-color: rgb(80, 0, 0);
}

input::placeholder {
  color: white !important;
}

/* Map styling */

.mapDiv {
  width: 350px;
}

.mapImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.findUsDiv {
  width: 277px;
}

.findUsDiv h2 {
  font-family: Inter;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  margin-bottom: 38px;
}

.findUsDiv p {
  font-family: "Nunito", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.findUsDiv p a {
  color: white;
  text-decoration: none;
}

/* Footer info */
.footer {
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer p {
  color: white;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 0;
}

/* Map styling */

.map-div {
  height: 350px;
  width: 350px;
}

.map-container {
  height: 100%;
  width: 100%;
}

/* Media queries */

@media screen and (max-width: 1030px) {
  .caravanImg,
  .caravanImgDiv {
    display: none;
  }

  .howItWorksImgContainer {
    padding-left: 0;
    padding-right: 0;
  }

  .contactInfo {
    flex-direction: column;
    height: auto;
  }

  .contactInfo > div {
    padding: 64px 20px 20px 20px;
  }

  .map-div {
    height: 250px;
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  /* Add the What we offer responsiveness here 800px */

  .carousel_position_1 {
    left: 6%;
  }

  .carousel_position_2 {
    left: -79%;
  }

  .carousel_position_3 {
    left: -163.5%;
  }

  .carousel_position_4 {
    left: -248.5%;
    display: flex;
    width: max-content;
    position: relative;
    transition: 0.6s;
  }

  .carousel_position_5 {
    left: -333.5%;
    display: flex;
    width: max-content;
    position: relative;
    transition: 0.6s;
  }

  .carousel_containers {
    background: black;
    width: 85vw;
  }

  .howItWorksImgContainer {
    flex-direction: column;
  }
  .howItWorksImgDiv {
    display: flex;
    text-align: left;
  }

  .howItWorksDiv {
    height: auto;
    padding-left: 35px;
    padding-bottom: 64px;
  }

  .howItWorksImageInnerDiv {
    margin-right: 25px;
  }
  .howItWorksImgDiv {
    width: 500px;
  }

  .howItWorksImg3 img {
    margin-bottom: 0;
  }

  .redBanner {
    display: none;
  }

  .footerBanner {
    display: none;
  }

  .mapDiv {
    margin-top: 40px;
    height: 300px;
  }
}

@media screen and (max-width: 650px) {
  #h2,
  .logo,
  .image_div {
    display: none;
  }

  .whatWeOfferDiv {
    margin-left: 0;
  }

  .safeStorage {
    margin-left: 0;
    overflow: auto;
  }

  .hope_storage_mobile_heading {
    display: block;
    position: relative;
    font-style: italic;
    font-weight: 600;
    font-size: 28px;
    width: 100vw;
    height: 55px;
    padding-top: 3px;
    background-color: lightgrey;
    padding-left: 40px;
  }

  .header_phone_number {
    display: none;
  }

  .header_phone_number_icon {
    display: block;
    font-size: 18px;
  }

  #mobileHeadingH2 {
    display: block;
    font-size: 23px;
    margin: 0;
  }

  /* .phone_number_div {
    width: 180px;
  } */

  .mobileHeading {
    position: absolute;
    left: calc(50% - 73px);
  }

  .mobile_caravan_img_div {
    display: block;
    width: 100vw;
    overflow: hidden;
  }

  .mobile_caravan_img {
    width: 300px;
    height: 245px;
    object-fit: cover;
    transform: scale(3.5);
    object-position: 69px -28px;
  }

  .whatWeOfferDiv {
    margin-left: 0;
  }

  .howItWorksDiv {
    margin-left: 0;
  }

  .howItWorksImgDiv {
    width: auto;
    padding-right: 15px;
  }

  .howItWorksImages {
    width: 170px;
    height: 165px;
  }

  .safeStorage_heading_div {
    width: 90vw;
  }

  .safeStorage_heading_div > h1 {
    font-size: 33px;
  }

  .findUsDiv p {
    font-size: 15px;
  }

  .safeStorage_heading_div,
  .contactBtnDiv {
    left: 45px;
  }
}
